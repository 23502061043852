body,
html {
  height: 100%;
  margin: 0 !important;
  touch-action: none;
  background-color: #fff;
  @media screen and (min-width: 600px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.page-background {
  background-color: white;
  height: 100vh;

  [data-state='iframe'] & {
    background-color: $gray_1050;
  }

  @supports (-webkit-touch-callout: none) {
    height: 100dvh;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 600px) {
    margin-right: auto;
    margin-left: auto;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card {
  [data-state='iframe'] & {
    background-color: $gray_1050;
  }
}

.card-group .card {
  border: none;
  width: 90vw;
  height: 100vh;

  @supports (-webkit-touch-callout: none) {
    height: 100dvh;
  }
}

.card-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 1.25em;
  background-color: white;
  overflow: hidden;
  height: 100%;

  [data-state='iframe'] & {
    background-color: $gray_1050;
  }
}

.marquee-text {
  font-family: Greed-CondensedSemiBold;
  font-size: 5em;
  background: -webkit-linear-gradient(0deg, $blue_300, $periwinkle_100, $mint_200, $green_100 100%); //hor_multi_2
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-col {
  padding: 0;
}

.header-column {
  flex-grow: 0;
}

.header-row {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  margin-bottom: 18px;
  min-width: 7.4em;
}

.card-fluz-logo {
  width: 4em;
  height: 1.7em;
}

.card-registration-header {
  font-family: Greed-CondensedSemiBold;
  font-size: 2em;
  color: $black_100;
  margin: auto;
  min-width: 6em;
  text-align: center;
}

.card-title {
  color: $black_100;
  font-family: Greed-CondensedSemiBold;
  font-size: 1.25rem;
}

.card-body {
  font-family: Greed-SemiBold;
  font-size: 1em;
  text-align: center;
  overflow-wrap: normal;
  padding: 0;
}

.card-registration-method {
  width: 50%;
  border-radius: 50%;
}

.card-more-options {
  color: $black_150;
  font-size: 0.875em;
}

/** Phone view */

.phone-field-wrapper {
  height: 50px; //must be same as .phone-input and .phone-country-dropdown
}

.phone-container {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 2em !important;
}

.phone-input-text {
  color: $black_100 !important;
  font-family: Area-Bold !important;
  font-size: 13px !important;
}

.phone-field-wrapper {
  position: relative;

  .phone-placeholder {
    @extend .phone-input-text;
    position: absolute;
    z-index: 1;
    left: 145px;
    top: 17px;
    pointer-events: none;
  }
}

.phone-input {
  @extend .phone-input-text;
  height: 6vh !important;
  border: none !important;
  border-radius: 0.625em !important;
  background-color: $black_500 !important;
  margin-left: 6em !important;
  padding: 0.5em !important;
  height: 6vh !important;
  width: 100% !important;
  padding-block: 25px !important;
  padding-inline: 11px !important;

  &::placeholder {
    color: $black_300;
  }
}

.phone-input-with-error {
  @extend .phone-input;
  border: 1px solid $red_400 !important;
}

.phone-country-dropdown {
  height: 6vh !important;
  width: 3em !important;
  border: none !important;
  border-radius: 0.625em !important;
  background-color: $black_500 !important;
}

.phone-country-dropdown .selected-flag {
  padding-left: 11px !important;
  width: 68px !important;
  height: 50px !important;
}

.react-tel-input {
  .selected-flag {
    background-color: $black_500 !important;
    border-radius: 10px !important;
    height: 50px !important;
  }
  .dial-code {
    visibility: hidden;
  }
  .country-list {
    background-color: $black_500 !important;
    border-radius: 15px !important;
    max-height: 312px !important;
    color: $black_150;
    width: 90vw !important;
    @media screen and (min-width: 600px) {
      width: 432px !important;
    }
    .highlight {
      background-color: inherit !important;
      color: $black_100;
    }
  }
}

.phone-country-dropdown {
  .arrow {
    margin-left: 9px !important;
    margin-top: -0.3em !important;
    width: 0.45em !important;
    height: 0.45em !important;
    border-right: none !important;
    border-top: none !important;
    border-left: 0.17em solid $black_100 !important;
    border-bottom: 0.17em solid $black_100 !important;
    right: 5px !important;
    transform: rotate(-45deg) !important;
    &.up {
      transform: rotate(135deg) !important;
      border-bottom: 0.17em solid $black_100 !important;
    }
  }
}

.phone-input-error {
  color: $red_400;
  font-family: Area-Bold;
  font-size: 9px;
  margin-bottom: 17px;
  margin-left: 80px;
  margin-top: 5px;
}

.phone-input-login-wrapper {
  display: contents;
}

.input-login {
  color: $red_400 !important;
  font-family: Area-Bold !important;
  font-size: 1em !important;
  min-height: 1em !important;
  min-width: 1em !important;
  text-decoration: underline;
  padding: 0;
  margin-bottom: 2px;
}

.phone-input-disclaimer {
  color: $black_300 !important;
  font-family: Area-Bold !important;
  font-size: 10px !important;
  margin-left: 1em;
  margin-right: 1em;
  text-align: center;
}

.g-recaptcha-disclaimer {
  @extend .phone-input-disclaimer;
  margin-bottom: 0.9rem;
}

.form-input-generic {
  font-family: Area-Bold !important;
  border: none !important;
  border-radius: 0.625em !important;
  background-color: $black_500 !important;
  color: $black_100 !important;
  padding: 17px 13px !important;
  height: 5vh !important;
  width: 100% !important;
  font-size: 13px !important;
  height: 50px !important;
}

/** Email view */
.email-input-disclaimer {
  color: $gray_500;
  font-family: Area-Bold !important;
  font-size: 0.64em !important;
  margin-top: 0.5em;
}

.email-input-error {
  @extend .email-input-disclaimer;
  color: $red_400;
}

.email-domains-container {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 90vw;
  padding: 0.25em;
  margin: 0;
  -ms-overflow-style: none; /* Hiding the scrollbar for IE and Edge */
  scrollbar-width: none; /* Hiding the scrollbar for Firefox */
}

.email-domains-container::-webkit-scrollbar {
  display: none; /* Hiding the scrollbar for Chrome */
}

.email-domain-list {
  padding: 0;
  display: flex;
  justify-content: space-between;
}

/** EnterTwoFACode view */
.pincode-input-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pincode-input-text {
  background-color: $black_500 !important;
  border-radius: 20%;
  width: 100% !important;
}

.pincode-error-message-container {
  max-width: 312px; // Max width of the pin-code input fields
  margin: auto;
}

.pincode-input-text-error {
  color: $red_400;
  font-family: Area-Bold;
  font-size: 0.74em; // Trying to make the error message fit in one line...
  margin-top: 0.2em;
  height: 1em;
  padding: 0px 4px;
  line-height: 14px;
}

.pincode-info-text {
  color: $gray_600;
  font-family: Area-Bold;
  font-size: 0.9em;
  margin-top: 1.5em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pincode-not-received {
  color: $black_100;
  display: flex;
  align-items: center;
  height: 3em;
  font-size: 13px;
  margin-right: 0.5em;
  font-weight: 800;
}

.pincode-please-wait {
  color: $gray_600;
  font-family: Area-Bold;
  font-size: 0.9em;
  width: 8.4em;
  text-align: left;
}

/** More options view */
.more-options-row {
  padding-left: 1em;
  padding-right: 1em;
}

.more-options-wrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $black_450;
  padding-bottom: 16px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 16px;
}

.more-options-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
}
.android-marquee-text-container {
  padding-bottom: 60px;
}

.marquee-text-container {
  [data-state='iframe'] & {
    background-color: $gray_1050;
  }
}

.hhh {
  min-width: 900px;
}

// Change container styling when viewing on non-mobile screens
@media screen and (min-width: 600px) {
  .page-background {
    background-color: white;
    display: flex;
    justify-items: center;

    [data-state='iframe'] & {
      background-color: $gray_1050;
    }
  }

  .card-container {
    align-items: center;
    height: 100vh;
  }

  .card-group > .card:first-child {
    // Needs to only apply to first child for initial view on mobile when there is marquee text as the last child.
    display: flex;
    justify-content: center;
    border: 1.5px solid $black_400;
    border-radius: 20px;
    padding: 2rem;
    height: 20em;
    width: 496px;
  }

  .marquee-text-container {
    display: none;
    margin-top: 0px;
    padding-top: 0px;
    background-color: red;

    [data-state='iframe'] & {
      background-color: $gray_1050;
    }
  }

  .phone-field-wrapper {
    height: 50px;
  }

  .phone-input {
    height: 50px !important;
  }

  .phone-country-dropdown {
    height: 50px !important;
    width: 68px !important;
  }

  .phone-input-error {
    margin-bottom: 26px;
  }

  .making-magic-container {
    border-radius: 20px;
    overflow: hidden;
    padding: 0;
    height: 640px;
    width: 500px;
  }
}

.form-text {
  color: $gray_500 !important;
  font-family: Area-Bold !important;
  font-size: 0.55rem !important;
  margin-bottom: 8px;
}

.warning-form-text {
  color: $red_400 !important;
}

.warn {
  color: $red_400 !important;
}

.form-group {
  margin-bottom: 5px;
  div.react-datepicker-wrapper {
    display: block;
  }
}

.auth-code-wrapper {
  text-align: center;
  caret-color: black;
}

.refer-background-container {
  border-radius: 15px;
  background-color: $powder;
  width: fit-content;
  background-image: url('../../assets/images/background-images/ReferBackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  position: relative;
  margin-bottom: 1rem;

  // linear-gradient border
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 15px;
    padding: 1.5px;
    background: linear-gradient(89.74deg, #fdc251 12.08%, #f7b4f5 59.27%, #a6b4f7 77.93%, #73b5ff 95.04%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

.refer-code-input {
  margin-bottom: 1rem;
}

.refer-container {
  display: flex;
  width: fit-content;
  padding: 20px 12px;
  border-radius: 15px;
  align-items: center;

  .registration-gif {
    width: 40px;
    z-index: 100;
  }

  .refer-text {
    margin-left: 0.5rem;
    .refer-title {
      font-family: Area-Bold;
      font-size: 9px;
      line-height: 10.8px;
      color: $black_100;
      font-weight: 900 !important;
    }
    .refer-subtitle {
      font-family: Greed-SemiBold;
      font-size: 17px;
      line-height: 17px;
      color: $black_100;
      padding-top: 4px;
    }
  }
}
.warning-form-input {
  border: 1px solid $red_400 !important;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(229, 83, 83, 0.25);
  }
}

.control.form {
  text-transform: 'capitalize' !important;
  ::placeholder {
    text-transform: 'capitalize' !important;
  }
}

.hidden {
  visibility: hidden;
}

.is-invalid {
  border: 1px solid $red_400 !important;
}

.link {
  text-decoration: underline;
}

.terms-and-conditions-wrapper {
  .terms-and-conditions {
    padding: 15px 25px 15px 15px;
    border-radius: 10px;
    overflow: auto;
    background-color: $black_500;
    height: 52vh;
    @media screen and (min-width: 600px) {
      height: 300px;
    }
    p,
    li {
      font-family: Area-Bold;
      font-size: 11px;
      color: $black_200;
      line-height: 19.8px;
    }
    h6 {
      font-family: Area-Extrabold;
      color: $black_100;
      padding-bottom: 0.6rem;
      font-size: 11px;
    }
  }
  .terms-and-conditions-body {
    p {
      color: $black_350;
      font-family: Area-Bold;
      font-size: 0.6rem;
    }
    a {
      @extend p;
      text-decoration: underline;
    }
    .terms-and-conditions-footer {
      @media screen and (min-width: 600px) {
        margin: 1rem;
      }

      .footer-links {
        padding-left: 4rem;
        padding-right: 4rem;
        text-align: center;
        @media screen and (min-width: 600px) {
          margin-top: 8px;
        }
      }
    }
  }
}

.cta {
  padding-top: 0;
  position: relative;
  .cta-card {
    border-radius: 20px;
    background-image: url('../../assets/images/background-images/sunbeambg.png');
    background-size: cover;
    background-size: 115% 115%;
    background-position: center;
    box-shadow: 0px 13px 24px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    @media screen and (max-width: 600px) {
      width: 100vw !important;
      height: 100vh !important;
      border-radius: 0px;
    }
    align-items: center;
    justify-content: center;
  }
  .cta-wrapper {
    background-color: #fff;

    [data-state='iframe'] & {
      background-color: $gray_1050;
    }

    padding: 20px;
    border-radius: 15px;
    width: 335px;
    .cta-header {
      text-align: center;
      h1 {
        font-family: Greed-SemiBold;
        color: $black_100;
        font-size: 36px;
      }
      p {
        font-family: Area-Bold;
        margin-bottom: 20px;
        color: black;
        font-size: 13px;
      }
      .cta-registration-title {
        font-family: Greed-Semibold;
        color: black;
        font-weight: 600;
        font-size: 52px;
      }
    }
    .cta-body {
      margin-top: 32px;
      margin-bottom: 18px;
    }
    .cta-content {
      display: flex;
      flex-direction: column;
      background-color: #faf7f7;
      flex: 1;
      justify-content: center;
      align-items: center;
      padding: 15px;
      border-radius: 15px;
      h5 {
        color: $natural_dark;
        font-family: Greed-SemiBold;
      }
      h5.qr-code-text {
        padding-top: 16px;
      }
      .cta-qr-code-container {
        .cta-qr-code {
          display: flex;
          align-items: center;
        }
        .cta-qr-code-text {
          display: flex;
          flex-grow: 4.1;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .cta-desktop-registration-content {
    margin-bottom: 30px;
    background-color: white;
    h5 {
      font-family: Greed-Semibold;
      color: black;
      font-weight: 600;
      font-size: 17px;
      margin-bottom: 0;
    }
    p {
      color: black;
      font-family: Area-Semibold;
      font-weight: 600;
      font-size: 13px;
      text-align: left;
      margin-top: 0;
    }
    .cta-desktop-registration-card-body {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;

      .cta-card-icon {
        padding: 10px;
        border-radius: 10px;
        margin-right: 10px;
        background-color: $natural_off_white;
      }
      .icon {
        width: 42px;
        height: 42px;
      }
    }

    .cta-desktop-registration-card-right {
      display: flex;
      flex: 5;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      background-color: #fff;

      [data-state='iframe'] & {
        background-color: $gray_1050;
      }
    }
  }
}

:focus-visible {
  outline: 1px solid black;
}

//fix IOS disabled input color
.form-control {
  &:disabled {
    color: #828f98 !important;
  }
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.date-input {
  text-transform: uppercase;
}

.arrow-down-icon {
  margin-left: 5px;
}

.react-code-input {
  ::-webkit-input-placeholder {
    color: $black_300;
  }
  input {
    text-align: center;
  }
}

//hide intercom button for mobile
@media screen and (max-width: 600px) {
  .intercom-namespace {
    .intercom-146u2g6 {
      visibility: hidden !important;
    }
    .intercom-dfosxs {
      visibility: hidden !important;
    }
  }
  .intercom-lightweight-app-launcher {
    visibility: hidden !important;
  }
}

.desktop-wrapper {
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100vw;
  padding: 20px;
  justify-content: space-between;
  align-items: center;

  &[data-state='iframe'] {
    background-color: $gray_1050;
  }

  .side-gradient-container {
    flex: 1;
    border-radius: 20px;
    background: linear-gradient(180deg, #fdc251 15.96%, #f7b4f5 60.46%, #a6b4f7 78.57%, #73b5ff 95.13%);
    display: flex;
    position: relative;
    height: 100%;
    max-width: 617px;
    @media screen and (max-width: 1000px) {
      display: none;
    }
    .fluz-logo {
      width: 69px;
      height: 30px;
      position: absolute;
      top: 25px;
      left: 25px;
    }
    .device-in-hand {
      object-fit: cover;
      position: relative;
      height: 100%;
      width: 100%;
      max-width: 617px;
      bottom: 0;
      left: 0;
      border-end-start-radius: 20px;
    }
  }
}

#staging-label {
  box-shadow: 0px 32px 64px -12px rgba(20, 20, 20, 0.14);
  background: linear-gradient(white, white) padding-box,
    linear-gradient(90deg, #d5cc0a 45.26%, #f7a6a6 64.05%, #c7d08f 79.5%, #e3d1df 99.7%) border-box;
  border-radius: var(--spacing-06, 12px);
  border: 2px solid transparent;
  height: 22px;
  width: 162px;
  justify-content: center;
  align-self: center;
}
.info-icon {
  margin-left: 5px;
  height: 15px;
  width: 15px;
  align-self: center;
}
